import React, { useEffect } from "react";
import { IState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { PriceListDetailsAction } from "../../../../redux/actions/price-list.action";
import { IPriceListData } from "./price-list.interface";

const userName = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const paswword = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";

type T = {
  priceListDetails?: IPriceListData;
  priceListData?: (
    username: string,
    password: string
  ) => Promise<IPriceListData>;
};

const PriceListComponent = ({
  priceListDetails,
  priceListData,
}: T): JSX.Element => {
  useEffect(() => {
    priceListData!(userName, paswword);
  }, []);

  useEffect(() => {
    if (priceListDetails?.data) {
    }
  }, [priceListDetails]);

  return (
    <div className="price-list">
      <h2>Price List</h2>
      <embed
        src={priceListDetails?.data.Url}
        type="application/pdf"
        width="100%"
        height="700px"
        style={{ border: "none" }}
      />
    </div>
  );
};

const mapStateToProps: any = (state: IState) => ({
  priceListDetails: state.priceListData,
});

const mapDispatchToProps: any = {
  priceListData: PriceListDetailsAction.GetDealerPriceListDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListComponent);
