import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyRegistartionService } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.service";
import {
  IAddonProductArray,
  IMBIProductPrice,
  IProductArray,
  IProductDeatails,
  ISelectedProduct,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

export class ProductDetailsAction {
  static GetProductDataSuccess(
    productData: IProductArray
  ): IAction<string, IProductArray> {
    return { type: ActionTypes.GET_PRODUCT_DETAILS, payLoad: productData };
  }

  static GetProductData<T extends IProductArray>(
    userName: string,
    password: string,
    langCode: string,
    mileage: string,
    modelYear: string,
    brandGroupId: number,
    brandId: number,
    coPartnerId: number,
    isConsiderCoPartner: boolean
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetProductData<T>(
          userName,
          password,
          langCode,
          mileage,
          modelYear,
          brandGroupId,
          brandId,
          coPartnerId,
          isConsiderCoPartner
        )
        .then((productData: T): T => {
          dispatch(ProductDetailsAction.GetProductDataSuccess(productData));
          return productData;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GetProductDataByIdSuccess(
    periodData: IProductDeatails
  ): IAction<string, IProductDeatails> {
    return { type: ActionTypes.GET_PRODUCT_BY_ID_DETAILS, payLoad: periodData };
  }

  static GetProductDataById<T extends IProductDeatails>(
    pId: number,
    userName: string,
    password: string
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetProductDataById<T>(pId, userName, password)
        .then((periodData: T): T => {
          dispatch(ProductDetailsAction.GetProductDataByIdSuccess(periodData));
          return periodData;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GetAddonProductDataByIdSuccess(
    addonProductData: IAddonProductArray
  ): IAction<string, IAddonProductArray> {
    return {
      type: ActionTypes.GET_ADDON_PRODUCT_DETAILS,
      payLoad: addonProductData,
    };
  }

  static GetAddonProductDataById<R, T extends IAddonProductArray>(
    data: any
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetAddonProductData<R, T>(data)
        .then((addonProductData: T): T => {
          dispatch(
            ProductDetailsAction.GetAddonProductDataByIdSuccess(
              addonProductData
            )
          );
          return addonProductData;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GetMBIProductPriceSuccess(
    mbiProductPrice: IMBIProductPrice
  ): IAction<string, IMBIProductPrice> {
    return {
      type: ActionTypes.GET_MBI_PRODUCT_PRICE,
      payLoad: mbiProductPrice,
    };
  }

  static GetMBIProductPrice<T extends IMBIProductPrice>(
    userName: string,
    paswword: string,
    capacity: string,
    productId: number,
    period: number,
    brandId: number
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetDealerSelectedProductPrice<T>(
          userName,
          paswword,
          capacity,
          productId,
          period,
          brandId
        )
        .then((IMBIProductPrice: T): T => {
          dispatch(
            ProductDetailsAction.GetMBIProductPriceSuccess(IMBIProductPrice)
          );
          return IMBIProductPrice;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static UpdateSelectedProductSuccess(
    selectedProducts: ISelectedProduct[]
  ): IAction<string, ISelectedProduct[]> {
    return {
      type: ActionTypes.UPDATE_SELECTED_PRODUCTS,
      payLoad: selectedProducts,
    };
  }

  static UpdateSelectedProduct<T extends ISelectedProduct>(
    data: any
  ): Function {
    return async (dispatch: any) => {
      dispatch(ProductDetailsAction.UpdateSelectedProductSuccess(data));
    };
  }
}
