import React, { useEffect, useState } from "react";

const WarrantyRegistrationSuccessComponent = () => {
  const [pdfUrl, setPdfUrl] = useState<string>("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pdfUrl = urlParams.get("pdfUrl");
    if (pdfUrl) {
      setPdfUrl(pdfUrl);
    }
  }, []);
  return (
    <div className="registration-complete">
      <h2>Registrering klar!</h2>
      <p>
        <a href={pdfUrl} target="_blank">
          Vänligen ladda ner garantibevis och villkor.
        </a>
      </p>
      <p>
        <a href="#">Skicka till fordonsägaren</a>
      </p>
      <h6>
        Du behöver ha Adobe Reader, Preview eller Safari (för Mac-användare) för
        att visa garantibevis. Du kan ladda ner och installera Adobe Reader{" "}
        <a href="https://get.adobe.com/reader/">här</a>.
      </h6>
    </div>
  );
};

export default WarrantyRegistrationSuccessComponent;
