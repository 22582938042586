import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { WarrantyOverviewDetailsAction } from "../../../../redux/actions/warranty-overview.action";
import { IState } from "../../../../redux/reducers";
import {
  IWarrantyOverviewGridData,
  IWarrantyOverviewGridItem,
} from "./warranty-overview.interface";

const userName = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const paswword = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";

type T = {
  warrantyOverviewData?: (
    username: string,
    password: string,
    searchData: string
  ) => Promise<IWarrantyOverviewGridData>;
  updateWarrantyOverviewData?: (
    warrantyOverviewGridData: any
  ) => Promise<IWarrantyOverviewGridData>;
  warrantyOverviewDetails?: IWarrantyOverviewGridData;
};

const WarrantyOverviewComponent = ({
  warrantyOverviewData,
  updateWarrantyOverviewData,
  warrantyOverviewDetails,
}: T) => {
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | null;
  }>({ key: "", direction: null });
  const itemsPerPage = 10;

  useEffect(() => {
    warrantyOverviewData!(userName, paswword, search);
  }, []);

  // Calculate the items to display based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = warrantyOverviewDetails?.data.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate total pages
  let totalPages = 0;
  if (warrantyOverviewDetails?.data) {
    totalPages = Math.ceil(warrantyOverviewDetails?.data.length / itemsPerPage);
  }

  const onSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    if (warrantyOverviewDetails?.data) {
      const sortedData = [...warrantyOverviewDetails?.data].sort((a, b) => {
        const keyA = a[key as keyof IWarrantyOverviewGridItem];
        const keyB = b[key as keyof IWarrantyOverviewGridItem];

        if (keyA < keyB) {
          return direction === "ascending" ? -1 : 1;
        }
        if (keyA > keyB) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });

      const updatedGridData = {
        data: sortedData,
      };

      updateWarrantyOverviewData!(updatedGridData);
      setSortConfig({ key, direction });
    }
  };

  const getIcon = (key: string) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      );
    }
    return <FaSort />;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchClick = () => {
    warrantyOverviewData!(userName, paswword, search);
  };

  const handleClearClick = () => {
    setSearch("");
    warrantyOverviewData!(userName, paswword, "");
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    if (warrantyOverviewDetails?.data) {
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(warrantyOverviewDetails?.data),
        "Dealer Overview"
      );
    }

    // Generate an Excel file and trigger a download
    XLSX.writeFile(
      workbook,
      `Dealer_Overview_${new Date().toDateString()}.xlsx`
    );
  };

  return (
    <div className="warranty-overview">
      <h2>Overview Warranty</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <button
          className="btn btn-primary search-btn"
          onClick={() => {
            handleSearchClick();
          }}
        >
          Search
        </button>
        <button
          className="btn btn-light"
          onClick={() => {
            handleClearClick();
          }}
        >
          Clear
        </button>
        <button
          className="btn btn-info"
          disabled={
            warrantyOverviewDetails?.data != undefined
              ? warrantyOverviewDetails?.data.length < 1
              : true
          }
          onClick={() => exportToExcel()}
        >
          Excel
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => onSort("id")}>Id {getIcon("id")}</th>
            <th onClick={() => onSort("regNo")}>Reg No {getIcon("regNo")}</th>
            <th onClick={() => onSort("vinNo")}>VIN No {getIcon("vinNo")}</th>
            <th onClick={() => onSort("productName")}>
              Product Name {getIcon("productName")}
            </th>
            <th onClick={() => onSort("validFrom")}>
              Valid From {getIcon("validFrom")}
            </th>
            <th onClick={() => onSort("validTo")}>
              Valid To {getIcon("validTo")}
            </th>
            <th onClick={() => onSort("brandName")}>
              Brand Name {getIcon("brandName")}
            </th>
            <th onClick={() => onSort("modelName")}>
              Model Name {getIcon("modelName")}
            </th>
            <th onClick={() => onSort("dealerName")}>
              Dealer Name {getIcon("dealerName")}
            </th>
            <th onClick={() => onSort("vehicleOwnerName")}>
              Vehicle Owner Name {getIcon("vehicleOwnerName")}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((row, index) => (
            <tr key={index}>
              <td>{row.id}</td>
              <td>{row.regNo}</td>
              <td>{row.vinNo}</td>
              <td>{row.productName}</td>
              <td>{row.validFrom}</td>
              <td>{row.validTo}</td>
              <td>{row.modelName}</td>
              <td>{row.brandName}</td>
              <td>{row.dealerName}</td>
              <td>{row.vehicleOwnerName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            disabled={index + 1 === currentPage}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return { warrantyOverviewDetails: state.warrantyOverviewData };
};

const mapDispatchToProps: any = {
  warrantyOverviewData:
    WarrantyOverviewDetailsAction.GetWarrantyOverviewGridDetails,
  updateWarrantyOverviewData:
    WarrantyOverviewDetailsAction.UpdateWarrantyOverviewGridDeatails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyOverviewComponent);
