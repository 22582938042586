import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../redux/reducers";
import { ProdctNameListDetailsAction } from "../../../../redux/actions/product-sell.action";
import {
  IDealerProduct,
  IProductNameListData,
} from "./products-sell.interface";

const userName = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const paswword = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const langCode = "sv-SE";

type T = {
  productNameListDetails?: IProductNameListData;
  productNameListData?: (
    username: string,
    password: string,
    langCode: string
  ) => Promise<IProductNameListData>;
  dealerProductDetails?: IDealerProduct;
  delaerProductData?: (
    username: string,
    pId: string,
    langCode: string
  ) => Promise<IDealerProduct>;
};

export const ProductsSellComponent = ({
  productNameListDetails,
  productNameListData,
  dealerProductDetails,
  delaerProductData,
}: T): JSX.Element => {
  useEffect(() => {
    productNameListData!(userName, paswword, langCode);
  }, []);

  const handleItemClick = (id: number) => {
    console.log("Clicked item:", id);
    delaerProductData!(userName, id.toString(), langCode);
  };

  useEffect(() => {
    if (productNameListDetails?.data) {
      delaerProductData!(
        userName,
        productNameListDetails?.data[0].pId.toString(),
        langCode
      );
    }
  }, [productNameListDetails]);

  return (
    <div className="products-sell">
      <h2>Your products </h2>
      <div className="row">
        <div className="col-md-4">
          <div className="product-overview">
            <header>
              <h2>Product Overview</h2>
            </header>
            <ul>
              {productNameListDetails?.data.map((product) => (
                <li onClick={() => handleItemClick(product.pId)}>
                  {product.productName}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          {dealerProductDetails?.data.productName != "" && (
            <div className="product-details">
              <h2>{dealerProductDetails?.data.productName}</h2>
              <p>{dealerProductDetails?.data.subHeading}</p>
              <p>{dealerProductDetails?.data.description}</p>
              <div className="terms">
                <a href="/terms">Terms</a>
              </div>
              {dealerProductDetails?.data.components &&
                dealerProductDetails?.data.components.length > 0 && (
                  <div>
                    <h6>Component scope</h6>
                    <ul>
                      {dealerProductDetails?.data.components.map(
                        (component, index) => (
                          <li key={index}>
                            {component.name} {component.description}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: any = (state: IState) => ({
  productNameListDetails: state.productNameListData,
  dealerProductDetails: state.dealerProductData,
});

const mapDispatchToProps: any = {
  productNameListData: ProdctNameListDetailsAction.GetProdctNameListListDetails,
  delaerProductData: ProdctNameListDetailsAction.GetProductDeatils,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsSellComponent);
