import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { PriceListService } from "../../App/one-platform/dealer/price-list/price-list.service";
import { IPriceListData } from "../../App/one-platform/dealer/price-list/price-list.interface";

export class PriceListDetailsAction {
  static GetDealerPriceListDetailsSuccess(
    priceListDetails: IPriceListData
  ): IAction<string, IPriceListData> {
    return {
      type: ActionTypes.GET_PRICE_LIST_DETAILS,
      payLoad: priceListDetails,
    };
  }
  static GetDealerPriceListDetails<T extends IPriceListData>(
    username: string,
    password: string
  ): Function {
    return async (dispatch: any) => {
      return await new PriceListService()
        .GetDealerPriceList<T>(username, password)
        .then((priceListDetails: T): T => {
          dispatch(
            PriceListDetailsAction.GetDealerPriceListDetailsSuccess(
              priceListDetails
            )
          );
          return priceListDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
