import React, { useState, ChangeEvent } from "react";
import {
  IAddonProductArray,
  IProductArray,
  ISelectedProduct,
} from "./warranty-registration.interface";
import { connect } from "react-redux";
import styled from "styled-components";
import { IState } from "../../../../redux/reducers";
import { Utils } from "../../shared";
import Skeleton from "../../shared/skeleton-component";

type T = {
  addonProductsArray: IAddonProductArray;
  onAddonProductSelect: (
    id: number,
    name: string,
    period: string,
    price: string
  ) => Promise<void>;
  removeAddonProduct: (id: number) => Promise<void>;
  selectedProducts: ISelectedProduct[];
  loadingAddonProducts: boolean;
};

const AddonSummeryComponent = ({
  addonProductsArray,
  onAddonProductSelect,
  removeAddonProduct,
  selectedProducts,
  loadingAddonProducts,
}: T): JSX.Element => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>("12");

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(e.target.value);
  };

  const selectAddOnProduct = (
    id: number,
    name: string,
    period: string,
    price: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      onAddonProductSelect(id, name, period, price);
    } else {
      removeAddonProduct(id);
    }
  };

  const SkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `;

  const SkeletonRow: React.FC = () => {
    return (
      <SkeletonWrapper>
        <Skeleton type="checkbox" />
        <Skeleton type="text" />
        <Skeleton type="price" />
        <Skeleton type="dropdown" />
        <Skeleton type="icon" />
      </SkeletonWrapper>
    );
  };

  return (
    <div className="mob-block mt-20">
      <div className="row">
        <div className="col-sm-6">
          {loadingAddonProducts ? (
            <div>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </div>
          ) : (
            <div className="row addon-products">
              <div className="col-sm-4">
                <p className="font-size-16 m-0">Additional products</p>
              </div>
              <div className="col-sm-8">
                {addonProductsArray.data.map((product, index) => (
                  <div className="row mb-5" key={index}>
                    <div className="col-1 flex v-center">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          selectAddOnProduct(
                            product.Id,
                            product.Name,
                            selectedPeriod,
                            product.PricePeriodList[0].Price,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                    <div className="col-5 font-size-14">
                      <p className="align-vertical-middle">{product.Name}</p>
                    </div>
                    <div className="col-3 font-size-14">
                      <p className="align-vertical-middle">
                        SEK {product.PricePeriodList[0].Price}
                      </p>
                    </div>
                    <div className="col-3 font-size-14">
                      <div className="flex v-center">
                        <select
                          className="period-dropdown addon-dropdown"
                          value={selectedPeriod}
                          onChange={(e) => handleChange(e)}
                        >
                          {product.PricePeriodList.map((period) => (
                            <option key={period.Period} value={period.Period}>
                              {period.Period}
                            </option>
                          ))}
                        </select>
                        <i className="fas fa-info-circle"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-6">
          <div className="summery-section">
            <div className="row">
              <div className="col-sm-4 font-size-14 mb-10 bold">
                Product name
              </div>
              <div className="col-sm-2 font-size-14 mb-10 bold">Start date</div>
              <div className="col-sm-2 font-size-14 mb-10 bold">End date</div>
              <div className="col-sm-2 font-size-14 mb-10 bold">Period</div>
              <div className="col-sm-2 font-size-14 mb-10 bold">Award</div>
            </div>
            {selectedProducts.map((product, index) => (
              <div className="row" key={product.id}>
                <div className="col-4 font-size-14 mb-5">{product.name}</div>
                <div className="col-2 font-size-14 mb-5">
                  {new Date(product.startDate).toLocaleDateString()}
                </div>
                <div className="col-2 font-size-14 mb-5">
                  {new Date(product.endDate).toLocaleDateString()}
                </div>
                <div className="col-2 font-size-14 mb-5">{product.period}</div>
                <div
                  className="col-2 font-size-14 mb-5"
                  style={{ textAlign: "right", paddingRight: 18 }}
                >
                  {Utils.currencyFormat("sv-SE", product.award)}
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-9 offset-9 font-size-14 mt-5">
                <p className="bold">
                  Total{" "}
                  <span
                    className="total-amount font-size-14"
                    style={{ textAlign: "right" }}
                  >
                    {Utils.currencyFormat(
                      "sv-SE",
                      selectedProducts.reduce(
                        (total, product) => total + product.award,
                        0
                      )
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return { vehicleDetails: state.vehicleData, brandsDetails: state.brandsData };
};

const mapDispatchToProps: any = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddonSummeryComponent);
