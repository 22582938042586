import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyOverviewService } from "../../App/one-platform/dealer/warranty-overview/warranty-overview.service";
import { IWarrantyOverviewGridData } from "../../App/one-platform/dealer/warranty-overview/warranty-overview.interface";

export class WarrantyOverviewDetailsAction {
  static GetWarrantyOverviewGridDetailsSuccess(
    warrantyDetails: IWarrantyOverviewGridData
  ): IAction<string, IWarrantyOverviewGridData> {
    return { type: ActionTypes.GET_WARRANTY_DETAILS, payLoad: warrantyDetails };
  }
  static GetWarrantyOverviewGridDetails<T extends IWarrantyOverviewGridData>(
    username: string,
    password: string,
    searchData: string
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyOverviewService()
        .GetWarrantyOverviewGridData<T>(username, password, searchData)
        .then((warrantyDetails: T): T => {
          dispatch(
            WarrantyOverviewDetailsAction.GetWarrantyOverviewGridDetailsSuccess(
              warrantyDetails
            )
          );
          return warrantyDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static UpdateWarrantyOverviewGridSuccess(
    warrantyDetails: IWarrantyOverviewGridData
  ): IAction<string, IWarrantyOverviewGridData> {
    return { type: ActionTypes.GET_WARRANTY_DETAILS, payLoad: warrantyDetails };
  }
  static UpdateWarrantyOverviewGridDeatails<T>(
    warrantyDetails: IWarrantyOverviewGridData
  ): Function {
    return async (dispatch: any) => {
      dispatch(
        WarrantyOverviewDetailsAction.UpdateWarrantyOverviewGridSuccess(
          warrantyDetails
        )
      );
    };
  }
}
