import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { IState } from "../../../../redux/reducers";
import styled from "styled-components";
import VehicleDetailsComponent from "./vehicle-details.component";
import ProductComponent from "./product.component";
import AddonSummeryComponent from "./addon-summery.component";
import VehicleOwnerComponent from "./vehicle-owner.component";

import {
  IProductArray,
  IVehicleDetailsFormData,
  IProductDeatails,
  IProduct,
  IAddonProductArray,
  IMBIProductPrice,
  IPersonalDetailsFormData,
  IDealerData,
  IBrandGroupData,
  ISelectedProduct,
  IBrandData,
  IWarrantyResponse,
  IPDFDoc,
} from "./warranty-registration.interface";
import { ProductDetailsAction } from "../../../../redux/actions/product-details.action";
import { VehicleDetailsAction } from "../../../../redux/actions/vehicle-details.action";
import { DealerDetailsAction } from "../../../../redux/actions/dealer-details.action";
import { PersonalDetailsAction } from "../../../../redux/actions/personal-details.action";
import { InsuranceDetailsAction } from "../../../../redux/actions/insurance-details.action";
import { useHistory } from "react-router-dom";
import Skeleton from "../../shared/skeleton-component";

const userName = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const paswword = "i2Z/xn10UCtbiWn2/MQe5gWn890d2rFvKJjhCg2vGNg=";
const langCode = "sv-SE";

type T = {
  vehicleDetails?: IVehicleDetailsFormData;
  productDetails?: IProductArray;
  periodDeatails?: IProductDeatails;
  personalDetails?: IPersonalDetailsFormData;
  addonProductDetails?: IAddonProductArray;
  mbiProductPrice?: IMBIProductPrice;
  dealerDetails?: IDealerData;
  brandGroupDetails?: IBrandGroupData;
  brandsDetails?: IBrandData;
  insuranceDetails?: IWarrantyResponse;
  selectedProducts?: ISelectedProduct[];
  pdfDocUrl?: IPDFDoc;
  productData?: (
    userName: string,
    paswword: string,
    langCode: string,
    milage: string,
    modelYear: string,
    brandGroupId: number,
    brandId: number,
    coPartnerId: number,
    isConsiderCoPartner: boolean
  ) => Promise<IProductArray>;
  periodData?: (
    pId: number,
    userName: string,
    paswword: string
  ) => Promise<IProductDeatails>;
  addonProductData?: (data: any) => Promise<IAddonProductArray>;
  mbiProductPriceData?: (
    userName: string,
    paswword: string,
    capacity: string,
    productId: number,
    period: number,
    brandId: number
  ) => Promise<IMBIProductPrice>;
  updateVehicleData?: (
    vehicleData: IVehicleDetailsFormData
  ) => Promise<IVehicleDetailsFormData>;
  dealerData?: (userName: string, paswword: string) => Promise<IDealerData>;
  brandGroupData?: (lCode: string) => Promise<IBrandGroupData>;
  updatePersonalData?: (
    personalData: IPersonalDetailsFormData
  ) => Promise<IPersonalDetailsFormData>;
  updateSelectedProduct?: (
    data: ISelectedProduct[]
  ) => Promise<ISelectedProduct[]>;
  createInsurance?: (
    data: IPersonalDetailsFormData
  ) => Promise<IPersonalDetailsFormData>;
  pdfUrl?: (insuranceId: number) => Promise<string>;
};

const WarrantyRegistrationForm = ({
  vehicleDetails,
  brandsDetails,
  productData,
  periodData,
  addonProductData,
  mbiProductPriceData,
  productDetails,
  addonProductDetails,
  selectedProducts,
  updateVehicleData,
  dealerDetails,
  dealerData,
  brandGroupDetails,
  brandGroupData,
  updatePersonalData,
  updateSelectedProduct,
  createInsurance,
  insuranceDetails,
  pdfUrl,
  pdfDocUrl,
}: T) => {
  const [isValidVehicleData, setIsValidVehicleData] = useState<boolean>(false);
  const [isProductSelect, setIsProductSelect] = useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [loadingAddonProducts, setLoadingAddonProducts] =
    useState<boolean>(false);
  const [loadingDealer, setLoadingDealer] = useState<boolean>(false);
  const [productsArray, setProductsArray] = useState<IProductArray>({
    data: [],
  });
  const [addonProductsArray, setAddonProductsArray] =
    useState<IAddonProductArray>({
      data: [],
    });
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [isMBIProductSelect, setIsMBIProductSelect] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    setLoadingDealer(true);
    dealerData!(userName, paswword);
    brandGroupData!(langCode);
  }, []);

  useEffect(() => {
    if (dealerDetails?.data.DealerId) {
      setLoadingDealer(false);
    }
  }, [dealerDetails]);

  useEffect(() => {
    if (productDetails) {
      let productArray: IProductArray = { data: [] };
      for (let index = 0; index < productDetails.data.length; index++) {
        periodData!(productDetails.data[index].pId, userName, paswword)
          .then((periodData: IProductDeatails) => {
            let productObject: IProduct = {
              pId: productDetails.data[index].pId,
              productName: productDetails.data[index].productName,
              excessAmount: productDetails.data[index].excessAmount,
              certificateDescription:
                productDetails.data[index].certificateDescription,
              maxKm: productDetails.data[index].maxKm,
              maxLength: productDetails.data[index].maxLength,
              periods: periodData.data.period,
              startDate: new Date(),
              period: 12,
            };

            productArray.data.push(productObject);
            productArray.data.sort((a, b) => a.pId - b.pId);
            setProductsArray(productArray);
            setLoadingProducts(false);
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }
  }, [productDetails]);

  useEffect(() => {
    if (insuranceDetails?.insuranceId) {
      pdfUrl!(insuranceDetails?.insuranceId);
      if (pdfDocUrl?.data.Url !== "") {
        history.push(
          `/warranty-registration-success?insId=${insuranceDetails?.insuranceId}&pdfUrl=${pdfDocUrl?.data.Url}`
        );
      }
    }
  }, [insuranceDetails?.insuranceId, pdfDocUrl?.data.Url]);

  useEffect(() => {
    if (selectedProducts && selectedProducts[0].startDate) {
      addonProductData!(addonRequestData());
    }
  }, [selectedProducts]);

  const addonRequestData = () => {
    const data = {
      dealerId: dealerDetails?.data.DealerId,
      productId: selectedProductId,
      keyPlateNumber: null,
      userName: "ASE#DEMO",
      password: "ASE#DEMO",
      arrivalDate: new Date().toLocaleDateString(),
      langCode: langCode,
      startDate: selectedProducts
        ? new Date(selectedProducts[0].startDate).toLocaleDateString() +
          "T00:00:00"
        : "",
      period: selectedProducts ? selectedProducts[0].period : "",
      endDate: selectedProducts
        ? new Date(selectedProducts[0].endDate).toLocaleDateString() +
          "T00:00:00"
        : "",
      regNo: vehicleDetails?.RegistrationNo,
      dofr: vehicleDetails?.ManufacYear + "-01" + "-01",
      modelYear: vehicleDetails?.ManufacYear + "-01" + "-01",
      engineCapacity: vehicleDetails?.EngineCapacity,
      enginePower: vehicleDetails?.EnginePower,
      brandGroupId: vehicleDetails?.BrandGroupId,
      brandId: getBrandId(vehicleDetails?.Brand),
      brandModelId: 0,
      brandModelName: vehicleDetails?.BrandModel,
      mileage: vehicleDetails?.Mileage,
      paymentMethodId: 2,
      excessAmount: 0.0,
    };

    return data;
  };

  useEffect(() => {
    if (addonProductDetails?.data) {
      setAddonProductsArray(addonProductDetails);
      setLoadingAddonProducts(false);
    }
  }, [addonProductDetails]);

  const onVehicleDetailsSubmit = async (
    vehicleData: IVehicleDetailsFormData
  ) => {
    setIsValidVehicleData(true);
    if (
      vehicleData?.ManufacYear &&
      vehicleData?.BrandGroupId &&
      vehicleData?.Mileage
    ) {
      await updateVehicleData!(vehicleData);
      setLoadingProducts(true);
      const coPartnerId = 0;
      const isConsiderCoPartner = true;
      await productData!(
        userName,
        paswword,
        langCode,
        vehicleData?.Mileage,
        vehicleData?.ManufacYear,
        vehicleData?.BrandGroupId,
        Number(getBrandId(vehicleDetails?.Brand)),
        coPartnerId,
        isConsiderCoPartner
      );
    }
  };

  const onProductIsSelected = async (
    isSelect: boolean,
    pid: number,
    name: string,
    period: number,
    startDate: string,
    endDate: string
  ) => {
    setIsProductSelect(isSelect);
    setSelectedProductId(pid);
    if (pid != 0 && vehicleDetails?.EngineCapacity) {
      setIsMBIProductSelect(true);
      setLoadingAddonProducts(true);
      await mbiProductPriceData!(
        userName,
        paswword,
        vehicleDetails?.EngineCapacity,
        pid,
        period,
        Number(getBrandId(vehicleDetails?.Brand))
      )
        .then(async (mbiProductPriceData: IMBIProductPrice) => {
          const data: ISelectedProduct[] = [
            {
              id: pid,
              name: name,
              period: period,
              startDate: startDate,
              endDate: endDate,
              award: mbiProductPriceData.price,
              type: "MBI",
            },
          ];
          await updateSelectedProduct!([...data]);
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      setIsMBIProductSelect(false);
    }
  };

  const onAddonProductSelect = async (
    id: number,
    name: string,
    period: string,
    price: string
  ) => {
    const date = new Date(
      selectedProducts ? selectedProducts[0].startDate : ""
    );
    const endDate = new Date(date.setMonth(date.getMonth() + Number(period)));
    const data: ISelectedProduct[] = [
      {
        id: id,
        name: name,
        period: Number(period),
        startDate: selectedProducts ? selectedProducts[0].startDate : "",
        endDate: endDate.toLocaleDateString(),
        award: Number(price),
        type: "AddOn",
      },
    ];
    if (selectedProducts) {
      await updateSelectedProduct!([...selectedProducts, ...data]);
    }
  };

  const removeAddonProduct = async (id: number) => {
    if (selectedProducts) {
      const newSelectedProductList: ISelectedProduct[] =
        selectedProducts.filter((product) => product.id !== id);
      await updateSelectedProduct!(newSelectedProductList);
    }
  };

  const onCustomerDetailsSubmit = async (
    personalData: IPersonalDetailsFormData
  ) => {
    await updatePersonalData!(personalData)
      .then(async (personalDetails: IPersonalDetailsFormData) => {
        const baName = personalData?.firstName + " " + personalData?.lastName;
        const vinNumber =
          vehicleDetails?.VIN != "IMPORT" ? vehicleDetails?.VIN : "";
        const insuranceDetails: any = {
          id: 0,
          keyPlateNumber: null,
          userName: "ASE#DEMO",
          password: "ASE#DEMO",
          arrivalDate: new Date().toLocaleDateString() + "T00:00:00",
          insurerId: 0,
          productId: selectedProducts ? selectedProducts[0].id : 0,
          period: selectedProducts ? selectedProducts[0].period : 0,
          startDate: selectedProducts
            ? new Date(selectedProducts[0].startDate).toLocaleDateString() +
              "T00:00:00"
            : "",
          endDate: selectedProducts
            ? new Date(selectedProducts[0].endDate).toLocaleDateString() +
              "T00:00:00"
            : "",
          regNo: vehicleDetails?.RegistrationNo,
          brandId: getBrandId(vehicleDetails?.Brand),
          brandModelId: 0,
          dofr: vehicleDetails?.ManufacYear + "-01" + "-01",
          modelYear: vehicleDetails?.ManufacYear + "-01" + "-01",
          brandModelName: vehicleDetails?.BrandModel,
          mileage: vehicleDetails?.Mileage,
          enginePower: vehicleDetails?.EnginePower,
          engineCapacity: vehicleDetails?.EngineCapacity,
          paymentMethodId: 2,
          langCode: langCode,
          sellerName: dealerDetails?.data.CusRepName,
          fuelTypeId: null,
          gearboxId: null,
          transmissionId: null,
          purchasedPrice: null,
          dealerId: null,
          buyerOwnerDetails: {
            isCompany: false,
            orgOrSecurityNo: personalData?.socialSecurityNumber,
            firstName: personalData?.firstName,
            orgOrLastName: personalData?.lastName,
            address: personalData?.address,
            billingAddress: baName + ", " + personalData?.zipCode,
            zipCode: personalData?.zipCode,
            city: personalData?.city,
            telephone: personalData?.teleMobile,
            cellPhone: personalData?.teleMobile,
            email: personalData?.email,
          },
          vinNumber: vinNumber,
          isSearchedByVIN: false,
          otherInfo: personalData?.information,
          AddOnProducts: getAddonProducts(),
          representativeId: null,
          isSuv: false,
        };
        await createInsurance!(insuranceDetails);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getBrandId = (brandName: string | undefined) => {
    return brandsDetails?.data.find((brand) => brand.name == brandName)?.id;
  };

  const getAddonProducts = () => {
    if (selectedProducts) {
      return selectedProducts.filter((product) => product.type === "AddOn");
    } else {
      return [];
    }
  };

  const SkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `;

  return (
    <div className="warranty-registration-module">
      {loadingDealer ? (
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-8" style={{ paddingTop: 30 }}>
            <Skeleton type="text" />
          </div>
          <div className="col-md-4" style={{ marginBottom: 20 }}>
            <Skeleton width="100%" height="150px" borderRadius="12px" />
          </div>
          <div className="col-md-6">
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
          </div>
          <div className="col-md-6">
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
            <SkeletonWrapper>
              <Skeleton type="text" />
              <Skeleton type="text" />
            </SkeletonWrapper>
          </div>
        </div>
      ) : (
        <VehicleDetailsComponent
          onVehicleDetailsSubmit={onVehicleDetailsSubmit}
          isValidVehicleData={isValidVehicleData}
          isMBIProductSelect={isMBIProductSelect}
          dealerDetails={dealerDetails}
          brandGroupDetails={brandGroupDetails}
        />
      )}

      {isValidVehicleData && (
        <div>
          {loadingProducts ? (
            <Skeleton width="100%" height="200px" borderRadius="12px" />
          ) : (
            <ProductComponent
              productDetails={productsArray}
              onProductIsSelected={onProductIsSelected}
            />
          )}
          {isProductSelect && (
            <>
              <AddonSummeryComponent
                addonProductsArray={addonProductsArray}
                onAddonProductSelect={onAddonProductSelect}
                removeAddonProduct={removeAddonProduct}
                selectedProducts={selectedProducts ? selectedProducts : []}
                loadingAddonProducts={loadingAddonProducts}
              />
              <VehicleOwnerComponent
                onCustomerDetailsSubmit={onCustomerDetailsSubmit}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return {
    vehicleDetails: state.vehicleData,
    productDetails: state.productData,
    periodDeatails: state.periodData,
    addonProductDetails: state.addonProductData,
    mbiProductPrice: state.mbiProductPrice,
    dealerDetails: state.dealerData,
    brandGroupDetails: state.brandGroupData,
    selectedProducts: state.selectedProducts,
    brandsDetails: state.brandsData,
    insuranceDetails: state.insuranceData,
    pdfDocUrl: state.pdfDocUrl,
  };
};

const mapDispatchToProps: any = {
  productData: ProductDetailsAction.GetProductData,
  periodData: ProductDetailsAction.GetProductDataById,
  addonProductData: ProductDetailsAction.GetAddonProductDataById,
  mbiProductPriceData: ProductDetailsAction.GetMBIProductPrice,
  updateVehicleData: VehicleDetailsAction.UpdateVehicleDeatails,
  dealerData: DealerDetailsAction.GetDealerDeatails,
  brandGroupData: DealerDetailsAction.GeBrandGroupsDeatails,
  updatePersonalData: PersonalDetailsAction.UpdatePersonalDeatails,
  updateSelectedProduct: ProductDetailsAction.UpdateSelectedProduct,
  createInsurance: InsuranceDetailsAction.CreateInsurance,
  pdfUrl: InsuranceDetailsAction.GetPDFDoc,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyRegistrationForm);
