import { HttpResponse, HttpService } from "../../core";

export class WarrantyRegistartionService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetVehicleData<T>(
    vehicleNumber: string,
    countryId: number,
    issearchedbyvin: boolean
  ): Promise<T> {
    return await this.httpService.get<T>(
      `Biluppgifter/GetDetailsForVehicle?number=${vehicleNumber}&countryId=${countryId}&issearchedbyvin=${issearchedbyvin}`
    );
  }

  async GetProductData<T>(
    userName: string,
    paswword: string,
    langCode: string,
    milage: string,
    modelYear: string,
    brandGroupId: number,
    brandId: number,
    coPartnerId: number,
    isConsiderCoPartner: boolean
  ): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetDealerFilteredProducts?u=${userName}&langCode=${langCode}&p=${paswword}&milage=${milage}&modelYear=${modelYear}-01-01&brandGroupId=${brandGroupId}&brandId=${brandId}&coPartnerId=${coPartnerId}&isConsiderCoPartner=${isConsiderCoPartner}`
    );
  }

  async GetProductDataById<T>(
    pId: number,
    userName: string,
    paswword: string
  ): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/ProductDetails?pId=${pId}&u=${userName}&p=${paswword}`
    );
  }

  async GetAddonProductData<R, T>(data: any): Promise<T> {
    return await this.httpService.post<R, T>(
      `SiteWarranty/SearchAddOnProductsCustomertoPayPrice`,
      data
    );
  }

  async GetDealerSelectedProductPrice<T>(
    userName: string,
    paswword: string,
    capacity: string,
    productId: number,
    period: number,
    brandId: number
  ): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetDealerProductPrice?u=${userName}&p=${paswword}&capacity=${capacity}&productId=${productId}&period=${period}&brandId=${brandId}`
    );
  }

  async GetPersonalData<T>(personalNo: string): Promise<T> {
    return await this.httpService.get<T>(
      `Bilvision/GetBilvisionPersonData?personalNo=${personalNo}`
    );
  }

  async GeDealerData<T>(userName: string, password: string): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetDealerName?usernameEncripted=${userName}&passwordEncripted=${password}`
    );
  }

  async GeBrandGroupData<T>(lCode: string): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetWebsiteBrandGroups?langCode=${lCode}`
    );
  }

  async GeBrandsData<T>(brandGroupId: string): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetWebsiteBrands?brandGroupId=${brandGroupId}`
    );
  }

  async GetWarrantyLetter<T>(insuranceId: number): Promise<T> {
    return await this.httpService.get<T>(
      `SiteWarranty/GetWarrantyAndTermsCertificatesPDF?insuranceId=${insuranceId}`
    );
  }

  async CreateInsurance<R, T>(data: any): Promise<T> {
    return await this.httpService.post<R, T>(`SiteWarranty/Add`, data);
  }
}
