export class ActionTypes {
  static readonly BEGIN_CALL_API: string = "BEGIN_CALL_API";
  static readonly API_CALL_ERROR: string = "API_CALL_ERROR";
  static readonly API_CALL_SUCCESS: string = "API_CALL_SUCCESS";
  static readonly GET_VEHICLE_DETAILS: string = "GET_VEHICLE_DETAILS";
  static readonly GET_PRODUCT_DETAILS: string = "GET_PRODUCT_DETAILS";
  static readonly GET_PRODUCT_BY_ID_DETAILS: string =
    "GET_PRODUCT_BY_ID_DETAILS";
  static readonly GET_ADDON_PRODUCT_DETAILS: string =
    "GET_ADDON_PRODUCT_DETAILS";
  static readonly GET_MBI_PRODUCT_PRICE: string = "GET_MBI_PRODUCT_PRICE";
  static readonly GET_PERSONAL_DETAILS: string = "GET_PERSONAL_DETAILS";
  static readonly GET_DEALER_DETAILS: string = "GET_DEALER_DETAILS";
  static readonly GET_BRAND_GROUPS_DETAILS: string = "GET_BRAND_GROUPS_DETAILS";
  static readonly GET_BRANDS_DETAILS: string = "GET_BRANDS_DETAILS";
  static readonly UPDATE_SELECTED_PRODUCTS: string = "UPDATE_SELECTED_PRODUCTS";
  static readonly GET_INSURANCE_DETAILS: string = "GET_INSURANCE_DETAILS";
  static readonly GET_PDF_DOC: string = "GET_PDF_DOC";
  static readonly GET_WARRANTY_DETAILS: string = "GET_WARRANTY_DETAILS";
  static readonly GET_PRICE_LIST_DETAILS: string = "GET_PRICE_LIST_DETAILS";
  static readonly GET_PRODUCT_NAME_LIST_DETAILS: string =
    "GET_PRODUCT_NAME_LIST_DETAILS";
  static readonly GET_DEALER_PRODUCT_DETAILS: string =
    "GET_DEALER_PRODUCT_DETAILS";
  static readonly GET_OFFER_LETTER_LOGIN_DETAILS: string =
    "GET_OFFER_LETTER_LOGIN_DETAILS";
}
