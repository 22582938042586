import AuthService from "./auth-service";
import { GuardComponent } from "../../utils/enum/guard-component-enum";
import { Utils } from "../../App/one-platform/shared";
export default class Guards {
  public static ComponentSpecificGuard(to: any, from: any, next: any): void {
    switch (to.meta.component) {
      case GuardComponent.PaymentCancel:
        Guards.PaymentRouterGuard(to, from, next);
        break;
      case GuardComponent.PaymentAccept:
        Guards.PaymentRouterGuard(to, from, next);
        break;
      default:
        next();
        Guards.SetCurrentPath(to);
    }
  }

  public static RequiredLogin(to: any, from: any, next: any): void {
    if (to.meta.auth) {
      if (AuthService.isAuthorized()) {
        next();
      } else {
        Guards.HandleRedirect(to, next);
      }
    } else {
      next();
    }
  }

  public static HandleRedirect(to: any, next: any): void {
    switch (to.meta.component) {
      case GuardComponent.PrivatePage:
        next.redirect(
          `${Utils.loginUrl}?callBkUrl=${to.match.path}`
        );
        break;

      default:
        next.redirect(
          `${sessionStorage.getItem("cur_page")}${to.location.search}`
        );
        break;
    }
  }

  public static PaymentRouterGuard(to: any, from: any, next: any): void {
    if (to.location.search == "?from=api") {
      next();
      Guards.SetCurrentPath(to);
    } else {
      next.redirect(
        `${sessionStorage.getItem("cur_page")}${to.location.search}`
      );
    }
  }
  public static SetCurrentPath(to: any): void {
    sessionStorage.setItem("cur_page", to.location.pathname);
  }
}
